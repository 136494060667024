<template>
  <div id="searchbar" class="h-12	w-full border-b searchbar_js">
    <div id="modal-bg" v-if="overlay" @click="closeModals" class="fixed top-0 left-0 z-20 w-screen	h-screen bg-black	bg-opacity-25 transition-all duration-500	"></div>

    <form @submit.prevent="moveToSearchList" id="search" class="flex pr-4">
      <FormulateInput
        type="text"
        class="border-none searchinput"
        v-model="searchTerm"
        validation-name="Search query"
        placeholder="e.g. Vaccines"
      />

      <button type="submit" class="text-sm ml-4 my-2 px-4 font-semibold border border-purple-200 text-purple-600 rounded-2xl">
        Search
      </button>
    </form>

    <div id="filters" class="border-l pr-4 py-2 flex">
      
      <div>
        <custom-button
          ButtonName="Channels"
          @click.native="modals.channels = !modals.channels"
          :Selected="searchChannels.length"
        />
        <Channels 
          v-if="modals.channels" 
          @closeModal="closeModals"
        />
      </div>

      <div>
        <custom-button
          ButtonName="Dates"
          ButtonIcon="dates"
          @click.native="modals.dates = !modals.dates"
        />
        <Date 
          v-if="modals.dates" 
          @closeModal="closeModals"
        />
      </div>

      <!-- <div>
        <custom-button
          ButtonName="Removed"
          ButtonIcon="removed"
          @click.native="modals.removed = !modals.removed"
        />
         <Removed 
          v-if="modals.removed" 
          @closeModal="closeModals"
        />
      </div> -->

    </div>

    <div id="sort" class="border-l pr-4 py-2">
      <custom-button
        ButtonName="Sort"
        ButtonIcon="sort"
        @click.native="modals.sort = !modals.sort"
      />
      <Sort 
        v-if="modals.sort" 
        :items="sortItems"
        @closeModal="closeModals"
      />
    </div>

    <div id="options" class="ml-auto pt-3.5 mr-4 border-l leading-12 pl-4">
      <div @click="modals.export = !modals.export" class="cursor-pointer font-sans text-sm">Export</div>
      <Export 
        v-if="modals.export" 
        @closeModal="closeModals"
      />
    </div>


  </div>
</template>

<script>
import CustomButton from '../CustomButton.vue'
import Channels from './drop-down/Channels.vue'
import Sort from './drop-down/Sort.vue'
// import Removed from './drop-down/Removed.vue'
import Export from './drop-down/Export.vue'
import Date from './drop-down/Date.vue'

import { warningToastConfig } from '@/helpers/constants'
import ToastMessages from '@/data/toast_messages.json'
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '@/main'

export default {
  name: 'Searchbar',
  components: {
    CustomButton, Channels, 
    Sort, 
    // Removed, 
    Export, 
    Date
  },
  watch: {
    'modals': {
       handler: function () {
         if (this._data.modals.channels || this._data.modals.sort || this._data.modals.removed || this._data.modals.export || this._data.modals.dates) {
           this._data.overlay = true;
         }
      },
      deep: true
    },
    'selectedBucket': {
      handler: function () {
        this.getChannels(this.selectedBucket.bucketId);
      }
    }
  },
  data () {
    return {
      searchTerm: "",
      bucketId: "",
      overlay: false,
      modals: {
        channels: false,
        dates: false,
        removed: false,
        sort: false,
        export: false
      },
      sortItems: [
        {
          text: 'Most Engaging First',
          value: 'views:desc',
          icon: 'camera'
        },
        {
          text: 'Least Engaging First',
          value: 'views:asc',
          icon: 'calendar'
        },
        {
          text: 'Newest First',
          value: 'date:desc',
          icon: 'calendar'
        },
        {
          text: 'Oldest First',
          value: 'date:asc',
          icon: 'calendar'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      searchBuckets: 'search/searchBuckets',
      searchState: 'search/searchState',
      selectedBucket: 'general/selectedBucket',
      searchChannels: 'search/searchChannels',
    })
  },
  methods: {
    ...mapActions({
      setSearchValues: 'search/setSearchValues',
      getChannels: 'search/getChannels',
      fetchLines: 'search/fetchLines'
    }),
    async moveToSearchList(){
      const bucketId = this.selectedBucket.bucketId;
      if (bucketId === null || this.searchTerm == "") {
        bucketId === null ? this.$root.$refs.featureNav.showBucketPickerInactive() : ""
        bucketId === null ? this.$toasted.show("Select a bucket.", warningToastConfig) : ""
        this.searchTerm === "" ? this.$toasted.show("Fill in a search term.", warningToastConfig) : ""
        return {}
      }

      if (this.$route.name != 'Search List' || this.$route.params.id != this.searchTerm) {
        this.$router.push(`/search/q/${this.searchTerm}/bucket/${this.selectedBucket.bucketId}`)
      } else {
        await this.setSearchTerm(this.searchTerm, this.$route.params.bid)
      }
    },
    async setSearchTerm(arg, bucketId){
      this.searchTerm = arg
      this.bucketId = bucketId;
      await this.callInitialSearch()
    },
    setSearchQueryToLocalStorage(){
      let searches = JSON.parse(this.$localStorage.get('searchQueries'))
      searches = searches == null ? [] : searches
      let isPresent = searches.find(elem => elem.name == this.searchTerm.trim())
      if(!isPresent) {
          searches.push({
          name: this.searchTerm.trim(),
          date: this.$moment(),
          bucket: this.bucketId
        })
      }
      searches = searches.slice(-5)
      this.$localStorage.set('searchQueries', JSON.stringify(searches))
    },
    async getBucketType(bid) {
      const { data } = await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-solo-bucket/${bid}`)
      return data.type;
    },
    async callInitialSearch() {
      if(this.searchTerm.length){
        this.setSearchQueryToLocalStorage()

        this.setSearchValues({
          term: this.searchTerm,
          bucketId: this.bucketId,
          type: await this.getBucketType(this.bucketId),
          from: 0
        })        
        await this.fetchLines({})
          .then(() => this.loading = false)
      } else {
        this.$toasted.show(ToastMessages.errors.empty_request, warningToastConfig)
      }
    },
    closeModals() {
      this.overlay = false;
      Object.keys(this.modals).forEach(v => this.modals[v] = false)
    }
  },
  mounted(){
    // this.getBuckets();
    eventBus.$on('setSearchTerm', this.setSearchTerm, this.$route.params.bid)

    if (this.selectedBucket.bucketId) {
      this.getChannels(this.selectedBucket.bucketId);
      // console.log('this.selectedBucket.bucketId')
    }

  },
  beforeDestroy(){
    eventBus.$off('setSearchTerm', this.setSearchTerm, this.$route.params.bid)
  }
}
</script>

<style lang="scss">
#searchbar {
  display: flex;
}

.searchinput div div input {
  height: 3rem;
  font-family: 'Ilisarniq';
  font-size: 1rem;
  background: none;
  padding-left: 1rem;
}

.searchinput div div input:focus {
  outline: none;
}

</style>