<template>
  <div class="mt-1 z-40 absolute ml-4 px-8 py-7 base-bg rounded channelsWrap zoom-big-enter">
    <div class="relative">

      <closeModal @closeModal="closeModal"/>
      
      <h2 class="text-black font-sans font-bold antialiased text-xl">
        Channels ({{ selectedChannelIds.length }}/{{ selectedChannels.length }})
      </h2>
      <p class="mt-2 text-black text-sm">
        Narrow down your search by specifiying channels within your selected bucket
      </p>
      <div class="flex flex-wrap justify-between overflow-auto max-h-80 mt-2">
        <div
          v-for="(channel, index) in channels" 
          :key="channel.channelId"
          class="flex w-60 h-20 mb-4 border rounded base-border-color py-2 pr-2 cursor-pointer overflow-auto"
          @click="setChannelsIDs(channel.channelId)"
          :class="{ 'bg-white':  selectedChannelIds.includes(channel.channelId), 'mr-4': (index + 1) % 2 != 0}"
        >
          <div class="w-1/4 flex items-center">

            <img class="rounded-full -ml-2 h-12 w-12" :src="channel.thumbnail" alt="">
          </div>
          <div class="w-3/4">
            <p class="text-sm text-black font-medium"> {{ channel.title }} </p>
            <p class="text-super-xs base-font-gray-lighter mt-1"> {{ channel.channelId }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import closeModal from '@/components/CloseModal'

export default {
  name: 'Channels',
  components: { closeModal },
  data(){
    return{
      searchText: '',
      channels: [],
      selectedChannels: [],
      baseChannelsList: []
    }
  },
  methods: {
    ...mapMutations({
      removeSelectedChannels: 'search/removeSelectedChannels',
      setChannelsIDs: 'search/setChannelsIDs'
    }),
    filterChannels(event){
      if(event.target.value.length) this.channels = this.baseChannelsList.filter(elem => elem.channel_name.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1)
      else this.channels = JSON.parse(JSON.stringify(this.baseChannelsList))
    },
    setBaseChannels(){
      this.baseChannelsList = JSON.parse(JSON.stringify(this.channels))
    },
    closeModal(){
      this.$emit('closeModal')
    }
  },
  computed:{
    ...mapGetters({
      searchChannels: 'search/searchChannels',
      selectedChannelIds: 'search/selectedChannelIds'
    })
  },
  async mounted(){
    this.channels = this.searchChannels;
    this.searchChannels.forEach((chan) => this.selectedChannels.push(chan.channelId))
    this.setBaseChannels(this.searchChannels);
  }
};
</script>

<style scoped lang="scss">
.channelsWrap{
  max-width: 577px;
}
</style>