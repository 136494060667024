<template>
  <div class="mt-3 z-40 absolute right-12 ml-4 px-6 pt-4 pb-14 bg-white rounded exportWrap rounded-md zoom-big-enter">
    <div class="relative">

      <closeModal @closeModal="closeModal"/>
      
      <h2 class="text-black font-sans font-bold antialiased text-xl border-b base-border-grey pb-4">
        Export Results
      </h2>
      <div class="flex">
        <div class="w-1/2 mt-6 px-2">
          <p class="font-mono text-sm base-font-gray-title">Regular exports</p>
          <p class="text-black text-sm mt-4 leading-none flex items-end">
            <span class="leading-none">Export visible transcripts</span>
          </p>
          <p class="text-xs base-font-gray mt-4 leading-4">
            This will export the transcripts that are currently visible in the interface in a CSV or TSV file.
          </p>
          <div class="mt-3 flex">
            <download-csv
              :data="lines"
              name="data.csv"
            >
              <button class="font-mono text-xs text-black font-medium base-bg-darker rounded-2xl py-1.5 px-3.5">
                CSV
              </button>
            </download-csv>
            
            <div>
              <button 
                class="font-mono text-xs text-black font-medium base-bg-darker rounded-2xl py-1.5 px-3.5 ml-7"
                @click="exportTSV"
              >
                TSV
              </button>
            </div>
            <!-- <a class="hidden" :href="`data:text/tsv;charset=utf-8,\ufeff${tsv}`" download="data.tsv" ref="downloadTSV"></a> -->
          </div>
          <p class="text-black text-sm mt-4 leading-none flex items-end">
            <span class="leading-none">Export Unique Videos</span>
          </p>
          <p class="text-xs base-font-gray mt-4 leading-4">
            Exports currently visible transcripts, but removes duplicate videos.
          </p>
          <download-csv
            :data="cleanLines"
            name="raditube-data-uniquevideos.csv"
          >
            <button 
              class="font-mono text-xs text-black font-medium base-bg-darker rounded-2xl py-1.5 px-3.5"
              @click="getUnique"
            >
              CSV
            </button>
          </download-csv>
        </div>
        <!-- <div class="w-1/2 mt-6 px-2">
          <p class="leading-none flex items-end">
            <span class="leading-none font-mono text-sm base-font-gray-title">API Access</span>
          </p>
          <p class="text-black text-sm mt-4 leading-none flex items-end">
            <span class="leading-none">Search</span>
          </p>
          <p class="text-xs base-font-gray mt-4 leading-4">
            Coming Soon, please reach out to info@raditube.com for more info
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import closeModal from '@/components/CloseModal'

export default {
  name: 'Buckets',
  components: { closeModal },
  data(){
    return{
      tsv: '',
      cleanLines: {},
    }
  },
  methods: {
    getUnique() {
      let res = [];
      let vidIds = [];
      if (this.lines.length) {
        this.lines.forEach((line) => {
          if (!vidIds.includes(line.videoId)) {
            res.push({
              videoId: line.videoId,
              channelId: line.channelId,
              user: line.user,
              dateUnix: line.date,
              mmddyyyy: new Date(line.date*1000).toLocaleDateString("en-US"),
              ddmmyyyy: new Date(line.date*1000).toLocaleDateString("en-GB"),
              text: line.textHTML,
              views: line.views
            })
          }
        vidIds.push(line.videoId)
        })

      }
      this.cleanLines = res;
      console.log(res);
    },
    exportTSV(){
      if(this.lines.length){
        const JSON_to_CSV = (arr, columns, delimiter) =>
          [
            columns.join(delimiter),
            ...arr.map(obj =>
              columns.reduce(
                (acc, key) => `${acc}${!acc.length ? '' : delimiter}"${!obj[key] ? '' : obj[key]}"`,
                ''
              )
            )
          ].join('\n');
        this.tsv = JSON_to_CSV(this.lines, Object.keys(this.lines[0]), '\t')
        setTimeout(() => this.$refs.downloadTSV.click(), 0)
      }else{
        console.error('No data to export')
      }
    },
    closeModal(){
      this.$emit('closeModal')
    }
  },
  computed: {
    ...mapGetters({
      lines: 'search/lines'
    })
  }
}
</script>

<style scoped lang="scss">
.exportWrap{
  width: 560px;
  transform: translateX(50%);
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
}
</style>